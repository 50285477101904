<template>
  <!-- <div class="comp-single-box"> -->
  <div class="Goods_item1" :class="`open-lv${item.level}`">
    <div style="margin-bottom:0.03rem" class="">
      {{ item.skins.dura_alias == "无" ? "" : item.skins.dura_alias }}
    </div>
    <div style="margin-bottom:0.06rem" class="Goods_pic3">
      <img :src="item.cover || item.skins.cover" alt="" />
    </div>
    <div style="margin-bottom:0.04rem" class="dura_alias">概率:{{ item.odds_percent }}</div>
    <div style="margin-bottom:0.04rem" class="elips1">{{ item.name || item.skins.name }}</div>
    <div class="box-price">
      <Cprice :size="0.08" :price="item.bean || item.skins.bean" />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    item: {},
  },
};
</script>

<style lang="scss">
// .comp-single-box {
.Goods_item1 {
  width: 0.92rem;
  // height: 1.1rem;
  flex-shrink: 0;
  text-align: center;
  color: #fff;
  margin: 0 0.028rem;
  margin-bottom: 0.08rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0.05rem;
  padding-top: 0.08rem;
  font-size: 0.1rem;
  text-align: center;
  div {
    margin-bottom: 0.02rem;
  }
  .odds_percent {
    color: #dea61f;
  }

  .Goods_pic3 {
    width: 100%;
    height: 0.35rem;
    // margin: 0.1rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 0.01rem;
    // margin-top: 0.1rem;

    img {
      width: 70%;
      vertical-align: middle;
    }
  }
}
</style>