<template>
  <div>
    <div class="comp-m-header-u">
      <div @click="goToLastPage" class="left-icon">
        <img
          style="width: 0.11rem"
          src="../../assets/images/mobile/m-home-header-icon2.png"
          alt=""
          srcset="" />
      </div>
      <div class="center-logo" @click="$router.push('/home')">
        <img
          style="height: 50%; display: block; margin-right: 0.3rem"
          src="../../assets/images/mobile/m-home-logo2.png"
          alt=""
          srcset="" />
      </div>
      <div v-if="showRightIcon" style="height: 100%">
        <div style="display: flex; height: 70%; margin-top: 0.15rem">
          <div v-if="!user.id" class="login-btn">
            <span @click="loginPopUpShow = true">登录</span>
            <span>/</span>
            <span @click="rsgPopUpShow = true">注册</span>
          </div>
          <div v-else style="display: flex">
            <div class="beanbox">
              <Cprice class="money" :price="user.bean" />
              <Cint class="money" :price="user.integral" />
            </div>
            <img
              @click="$router.push('/About')"
              style="
                width: 0.28rem;
                height: 0.28rem;
                border-radius: 50%;
                overflow: hidden;
              "
              :src="user.avatar"
              alt=""
              srcset="" />
          </div>
        </div>
        <div
          v-if="showRightMeanIcon && tabList.length"
          @click="rightIconOnClick"
          class="right-icon">
          <img
            style="width: 0.3rem"
            src="../../assets/images/mobile/m-home-header-icon1.png"
            alt=""
            srcset="" />
        </div>
      </div>

      <div v-else></div>
      <!-- 右侧侧边栏弹窗 -->
      <van-overlay
        @click="rightNavShow = false"
        :lock-scroll="false"
        :show="rightNavShow">
        <transition name="fade">
          <div class="right-popup">
            <div class="right-popup-mean">
              <div
                @click="chooseTabOnClick(item)"
                class="tab-item"
                :class="[{ 'active-item': chooseTab.id === item.id }]"
                v-for="item in tabList"
                :key="item.id">
                {{ item.name }}
              </div>
            </div>
          </div>
          <!-- <div v-show="rightNavShow" class="left-box">
          <div @click="rightNavShow = !rightNavShow" class="close-btn">
            <img
              style="width: 0.2rem; height: 0.2rem"
              src="../../assets/images/mobile/m-home-close.png"
              alt=""
              srcset=""
            />
          </div>
          <div
            @click="meanItemOnClick(item)"
            v-for="item in meanList"
            :key="item.id"
            class="left-box-item"
            :class="[{ 'active-item': activeId === item.id }]"
          >
            <div class="left-img">
              <img
                v-if="item.icon"
                style="width:100%;height100%;"
                :src="
                  require(`../../assets/images/mobile/leftpopupgroup/${
                    activeId === item.id ? item.aicon : item.icon
                  }.png`)
                "
                alt=""
              />
            </div>
            <div class="right-text">
              {{ item.text }}
            </div>
            <div v-show="activeId === item.id" class="child-box">
              <div
                @click.stop="meanItemOnClick(vItem)"
                v-for="vItem in item.children"
                :key="vItem.id"
                class="left-box-item-child"
              >
                <div class="child-left-img">
                  <img
                    v-if="vItem.icon"
                    style="width:100%;height100%;"
                    :src="
                      require(`../../assets/images/mobile/leftpopupgroup/${vItem.icon}.png`)
                    "
                    alt=""
                  />
                </div>
                <div class="child-right-text">
                  {{ vItem.text }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
        </transition>
      </van-overlay>
      <!-- 登录弹窗 -->
      <van-overlay :lock-scroll="true" :show="loginPopUpShow">
        <div class="login-popup">
          <LoginPopup @closePopup="loginPopUpShow = false" />
        </div>
      </van-overlay>
      <!-- 注册弹窗 -->
      <van-overlay :lock-scroll="true" :show="rsgPopUpShow">
        <div class="reg-popup">
          <RegPopup @closePopup="rsgPopUpShow = false" />
        </div>
      </van-overlay>
    </div>
    <div style="height: 0.6rem; width: 100%"></div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import LoginPopup from "@/components/common/LoginPopup";
import RegPopup from "@/components/common/RegPopup";
export default {
  components: {
    LoginPopup,
    RegPopup,
  },
  data() {
    return {
      rightNavShow: false,
      loginPopUpShow: false,
      rsgPopUpShow: false,
      chooseTab: {},
    };
  },
  props: {
    propType: {
      type: [String, Number],
      default: 0,
    },
    showRightIcon: {
      type: Boolean,
      default: true,
    },
    showRightMeanIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    rightIconOnClick() {
      this.rightNavShow = !this.rightNavShow;
      if (this.rightNavShow) {
        this.chooseTab = this.tabList[0];
        for (let index = 0; index < this.tabList.length; index++) {
          const item = this.tabList[index];
          if (
            this.$route?.query?.tabType &&
            this.$route?.query?.tabType == item.query.tabType
          ) {
            this.chooseTab = item;
            break;
          } else {
            if (item.path === this.$route.path) {
              this.chooseTab = item;
              break;
            }
          }
        }
      }
    },
    goToLastPage() {
      this.rightNavShow = false;
      setTimeout(() => {
        this.$router.go(-1);
      }, 300);
    },
    chooseTabOnClick(item) {
      this.chooseTab = item;
      if (item.path) {
        this.$router.push({
          path: item.path,
          query: {
            ...this.$route.query,
            ...item.query,
          },
        });
      }
      this.rightNavShow = false;
      if (item.name == "退出登录") {
        this.SignOut();
      }
    },
    //退出
    SignOut() {
      this.Leave();
      window.location.replace("/Home");
    },
    ...mapMutations(["Leave", "User"]),
  },
  computed: {
    ...mapState(["user"]),
    tabList() {
      const { propType } = this;
      let tabList = [];
      if (propType == 1) {
        tabList = [
          {
            id: 6,
            name: "高光时刻",
            path: "/Highlight",
          },
          {
            id: 1,
            name: "个人资料",
            path: "/About",
          },
          {
            id: 2,
            name: "我的背包",
            path: "/Warehouse",
          },
          {
            id: 3,
            name: "钱包记录",
            path: "/BagRecord",
          },
          {
            id: 4,
            name: "钱包充值",
            path: "/BagRecharge",
          },
          {
            id: 5,
            name: "分享奖励",
            path: "/shareReward",
          },

          {
            id: 7,
            name: "退出登录",
          },
        ];
      }
      if (propType == 2) {
        tabList = [
          {
            id: 21,
            name: "热门道具",
            headerName: "幸运饰品",
            path: "/Lucky",
            query: {
              tabType: 0,
            },
          },
          {
            id: 22,
            name: "掉落记录",
            path: "/Lucky",
            query: {
              tabType: 1,
            },
          },
          // {
          //   id: 23,
          //   name: "十佳掉落",
          //   path: "/Lucky",
          //   query: {
          //     tabType: 2,
          //   },
          // },
        ];
      }
      if (propType == 3) {
        tabList = [
          {
            id: 31,
            name: "经典盲盒",
            path: "/classicsBox",
            query: {
              tabType: 0,
            },
          },
          {
            id: 32,
            name: "掉落记录",
            path: "/classicsBox",
            query: {
              tabType: 1,
            },
          },
          {
            id: 33,
            name: "十佳掉落",
            path: "/classicsBox",
            query: {
              tabType: 2,
            },
          },
        ];
      }
      if (propType == 4) {
        tabList = [
          {
            id: 41,
            name: "会员对战",
            path: "/Battle",
            query: {
              tabType: 0,
            },
          },
          {
            id: 42,
            name: "游戏历史",
            path: "/Battle",
            query: {
              tabType: 1,
            },
          },
          {
            id: 43,
            name: "十佳对战",
            path: "/Battle",
            query: {
              tabType: 2,
            },
          },
        ];
      }
      if (propType == 5) {
        tabList = [
          {
            id: 51,
            name: "物品列表",
            path: "/OpeningBox",
            query: {
              tabType: 0,
            },
          },
          {
            id: 52,
            name: "历史掉落",
            path: "/OpeningBox",
            query: {
              tabType: 1,
            },
          },
          // {
          //   id: 53,
          //   name: "掉落记录",
          //   path: "/OpeningBox",
          //   query: {
          //     tabType: 2,
          //   },
          // },
          // {
          //   id: 54,
          //   name: "类型统计",
          //   path: "/OpeningBox",
          //   query: {
          //     tabType: 3,
          //   },
          // },
        ];
      }
      if (propType == 6) {
        tabList = [
          {
            id: 61,
            name: "商城",
            path: "/mall",
            hide: true,
          },
        ];
      }
      if (propType == 7) {
        tabList = [
          {
            id: 61,
            name: "购物车",
            path: "/mallcar",
          },
        ];
      }

      return tabList;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active {
  transition: all 0.4s ease;
}

.fade-leave-active {
  transition: all 0.4s ease;
}

.fade-enter {
  transform: translateX(-2rem);
}

.fade-leave-to {
  transform: translateX(-2rem);
}

.comp-m-header-u {
  position: relative;
  width: 3.75rem;
  height: 0.6rem;
  padding: 0rem 0.18rem 0 0.18rem;
  background: #091c31;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

  .login-btn {
    color: #fdb940;
    font-size: 0.16rem;
  }

  .login-popup {
    width: 3.39rem;
    min-height: 4.11rem;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .reg-popup {
    width: 3.39rem;
    min-height: 4.11rem;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .left-box {
    width: 2rem;
    height: 100vh;
    background: url(../../assets/images/mobile/m-home-bg1.png) no-repeat center;
    background-size: 100% 100%;
    padding-top: 0.26rem;
    padding-left: 0.4rem;
    position: relative;

    .close-btn {
      position: absolute;
      top: 0.13rem;
      right: -0.34rem;
    }

    .left-box-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: #a8a8a8;
      font-size: 0.18rem;
      margin-bottom: 0.26rem;

      .left-img {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.18rem;
      }

      .child-box {
        width: 100%;

        .left-box-item-child {
          display: flex;
          align-items: center;
          font-size: 0.14rem;
          margin-top: 0.18rem;
          color: #a8a8a8;

          .child-left-img {
            width: 0.2rem;
            height: 0.2rem;
            margin-right: 0.18rem;
          }

          .child-right-text {
          }
        }
      }
    }

    .active-item {
      color: #e9431b;
    }
  }

  .center-logo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .beanbox {
    height: 100%;
    padding-right: 0.06rem;

    .money {
      justify-content: flex-start;
    }
  }

  .right-icon {
    width: 0.3rem;
    height: 0.5rem;
    position: absolute;
    right: 0.2rem;

    .login-btn {
      color: #fdb940;
      font-size: 0.16rem;
    }
  }

  .van-overlay {
    height: calc(100% - 0.44rem);
    bottom: -0.1rem;
    top: unset;

    .right-popup {
      position: relative;

      .right-popup-mean {
        position: absolute;
        right: 0.18rem;
        top: 0;
        min-height: 1rem;
        width: 1.41rem;
        background: url(../../assets/images/mobile/m-mine-kuang3.png) no-repeat
          center;
        background-size: 100% 100%;
        padding: 0.12rem;

        .tab-item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0.12rem;
          min-height: 0.27rem;
          background: url(../../assets/images/mobile/m-mine-kuang2.png)
            no-repeat center;
          background-size: 100% 100%;
          font-size: 0.12rem;
        }

        .active-item {
          color: #36d0fd;
          background: url(../../assets/images/mobile/m-mine-kuang1.png)
            no-repeat center;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
