import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload"; //图片懒加载
import VueClipboard from "vue-clipboard2"; //复制文本
import echarts from "echarts"; //引入echarts
import Rem from "@/utils/rem.js"; //px转rem文件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vant from "vant";
import "vant/lib/index.css";
import MySocket from "./network/socket";
Rem(375, 750, 100);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(MySocket);
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueClipboard);
Vue.use(VueLazyload, {
  loading: require("@/assets/images/loading-svg/loading-bars.svg"),
  error: require("@/assets/images/other/none.jpg"),
});

const context = require.context("@/components/newCommon", false, /\.vue$/);
context.keys().forEach((key) => {
  const componentName = key.replace(/^\.\//, "").replace(/\.vue$/, "");
  const module = context(key).default || context(key);
  Vue.component(componentName, module);
});

import SingleBox from "@/components/common/Pages/SingleBox";
import OddsList from "@/components/common/Pages/OddsList";
import Cprice from "@/components/common/Cprice";
import Hprice from "@/components/common/Hprice";

import Cint from "@/components/common/Cint";
import MeanHeader from "@/components/common/MeanHeader.vue";
import UserHeader from "@/components/common/UserHeader.vue";
import Tabbar from "@/components/common/Tabbar.vue";
// 分页组件
import Pagination from "@/components/changepage/index.vue";
Vue.component("MySingleBox", SingleBox);
Vue.component("MyOddsList", OddsList);
Vue.component("Cprice", Cprice);
Vue.component("Hprice", Hprice);
Vue.component("Cint", Cint);
Vue.component("MeanHeader", MeanHeader);
Vue.component("UserHeader", UserHeader);
Vue.component("Tabbar", Tabbar);
Vue.component("Pagination", Pagination);
// 解决定位显示为顶部问题
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
// 正式环境清除所有console.log
if (process.env.NODE_ENV === "production") {
  if (window) {
    window.console.log = function () {};
  }
}

//H5跳PC
var sUserAgent = navigator.userAgent.toLowerCase();
const flag = sUserAgent.match(
  /(ipod|iphone os|midp|ucweb|android|windows ce|windows mobile)/i
);
console.log(process.env.NODE_ENV === "production" ? "正式环境" : "test环境");
console.log(flag ? "移动端" : "PC端");
function checkUserAgent() {
  if (process.env.NODE_ENV === "production") {
    if (!flag) {
      location.href = "https://tmskins.com";
    } else {
      new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount("#app");
    }
  } else {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
}
checkUserAgent();

// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");
