<template>
  <div class="comp-header">
    <div class="comp-header-main">
      <div @click="goBackOnClick" class="left-box flex-s">
        <div class="left-arrow"></div>
        <div class="title">{{ title }}</div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "CompHeader",
  props: {
    title: {
      type: String,
      default: "背包",
    },
    backFunc: {
      type: Function,
      default: function () {
        this.$router.go(-1);
      },
    },
  },
  methods: {
    goBackOnClick() {
      this.backFunc();
    },
  },
};
</script>

<style lang="scss">
.comp-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.5rem;
  z-index: 99;
  @include bgImg("comp-header-bg");
  .comp-header-main {
    position: absolute;
    left: 0.12rem;
    top: 0.06rem;
    .left-arrow {
      width: 0.32rem;
      height: 0.32rem;
      @include bgImg("header-left-arrow");
      margin-right: 0.04rem;
    }
  }
}
</style>
