import Vue from "vue";
import VueRouter from "vue-router";
import myMixin from "./mixin";
import myParate from "./parate";
import { Message } from "element-ui";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
//解决vue路由重复导航错误
const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/OutIndex/index.vue"),
    redirect: "/Home",
    children: [
      ...myMixin,
      ...myParate,
      {
        //CSGO
        path: "/Home",
        name: "Home",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        //背包
        path: "/Bag",
        name: "Bag",
        component: () => import("@/views/Bag/index.vue"),
      },
      {
        //充值
        path: "/Recharge",
        name: "Recharge",
        component: () => import("@/views/BagRecharge/index.vue"),
      },
      {
        //盲盒
        path: "/BlindBox",
        name: "BlindBox",
        component: () => import("@/views/BlindBox/index.vue"),
      },
      {
        // 推广中心
        path: "/Extension",
        name: "Extension",
        component: () => import("@/views/Extension/index.vue"),
      },

      {
        //幸运开箱
        path: "/Lucky",
        name: "Lucky",
        component: () => import("@/views/Lucky/index.vue"),
      },

      {
        //商店
        path: "/DustMall",
        name: "DustMall",
        component: () => import("@/views/DustMall/index.vue"),
      },

      {
        //合作伙伴
        path: "/Cooperation",
        name: "Cooperation",
        component: () => import("@/views/Cooperation/index.vue"),
      },

      // {
      //   //箱子对战
      //   path: "/BoxFight",
      //   name: "BoxFight",
      //   component: () => import("@/views/BoxFight/index.vue"),
      // },
      {
        //新箱子对战
        path: "/Battle",
        name: "Battle",
        component: () => import("@/views/Battle/index.vue"),
      },
      {
        //活动页面
        path: "/Activity",
        name: "Activity",
        component: () => import("@/views/Activity/index.vue"),
      },
      {
        //活动页面(开箱)
        path: "/Activity_open",
        name: "Activity_open",
        component: () => import("@/views/Activity/Activity_open.vue"),
      },
      {
        //正常开箱
        path: "/OpeningBox",
        name: "OpeningBox",
        component: () => import("@/views/OpeningBox/index.vue"),
      },
      {
        //正常开箱
        path: "/VIP",
        name: "VIP",
        component: () => import("@/views/VIP/index.vue"),
      },
      {
        //经典盲盒
        path: "/classicsBox",
        name: "classicsBox",
        component: () => import("@/views/classicsBox/index.vue"),
      },
      {
        //个人中心
        path: "/NewUser",
        name: "NewUser",
        component: () => import("@/views/NewUser/index.vue"),
      },
      {
        //福利房
        path: "/Roll",
        name: "DustRoll",
        component: () => import("@/views/DustRoll/index.vue"),
      },
      {
        //饰品-历史记录
        path: "/LuckyHistory",
        name: "LuckyHistory",
        component: () => import("@/views/Lucky/recode.vue"),
      },
    ],
  },
  {
    path: "/NotOpen",
    name: "NotOpen",
    component: () => import("@/views/NotOpen/index.vue"),
  },
  {
    path: "/Payment",
    name: "Payment",
    component: () => import("@/views/Payment/index.vue"),
  },
  // dust新增内容
  {
    // 对战首页
    path: "/dustBatter",
    name: "dustBatter",
    component: () => import("@/views/dustBattle/index.vue"),
  },
  {
    path: "/createBattle",
    name: "createBattle",
    component: () => import("@/views/dustBattle/createBattle.vue"),
  },
  {
    path: "/battleRoom",
    name: "battleRoom",
    component: () => import("@/views/dustBattle/battleRoom.vue"),
  },
  {
    //高光时刻
    path: "/Best",
    name: "Best",
    component: () => import("@/views/NewUser/Best.vue"),
  },
  {
    //记录查询
    path: "/Chargerecord",
    name: "Chargerecord",
    component: () => import("@/views/NewUser/Chargerecord.vue"),
  },

  {
    //福利房
    path: "/RoomDetail",
    name: "RoomDetail",
    component: () => import("@/views/DustRoll/RoomDetail.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// 导航守卫
router.beforeEach((to, from, next) => {
  if (
    to.path === "/Warehouse" ||
    to.path === "/NewUser" ||
    to.path === "/Extension" ||
    to.path === "/Recharge" ||
    to.path === "/CreatingRooms" ||
    to.path === "/WelfareInfo"
  ) {
    let token = localStorage.getItem("token");
    if (token === null || token === "") {
      next("/Home");
      Message({
        message: "您还未登陆，请先登陆!",
        type: "error",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
