<template>
  <div class="pagination-container">
    <el-pagination :hide-on-single-page="true" :small="true" :background="background" prev-text='上一页' next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout" :pager-count="pagerCount" :total="total" v-bind="$attrs"
      @current-change="handleCurrentChange"/>
  </div>

</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },

    limit: {
      type: Number,
      default: 10
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default:5
    },
    layout: {
      type: String,
      default: 'prev, pager, next,total'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 当前页处于第几页
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('update:page', val);
      }
    },
    // 当前页条数
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit('update:limit', val);
      }
    }
  },
  methods: {
    // el-pagination 组件自定义事件回调 参数val：当前页码处于第几页
    handleCurrentChange(val) {
      // 触发父组件pagination自定义事件 传递当前页码数currentPage跟当前页显示多少条数limit
      this.$emit('pagination', { page: val, limit: this.pageSize });

    }
  }

}
</script>

<style lang="scss" scoped>
.pagination-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep.el-pagination {
  display: flex;
  align-items: center;
}
::v-deep.el-pagination span {
  font-size: 0.08rem;
  height:unset;
  line-height:unset;
}
::v-deep.el-pagination .btn-prev {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep.el-pagination .btn-next {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep.el-pagination .el-pager .more::before {
  line-height: unset;
}
::v-deep.el-pagination__total {
  font-size:0.08rem !important;
  font-family: PingFang SC, PingFang SC;
  font-weight: 300;
  color: #ffffff !important;
}
// prev和next箭头的样式
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  width: 0.3rem !important;
  height: 0.18rem !important;
  background: #1D1300 !important;
  border: 0.01rem solid #372E1D;
  font-size: 0.08rem !important;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #ffffff !important;
  margin:0 0.05rem !important;
}
// 页码样式
::v-deep .el-pager li {
  width: 0.2rem !important;
  height: 0.18rem !important;
  background-color: #1D1300 !important;
  border: 0.01rem solid #372E1D !important;
  font-size: 0.08rem !important;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #ffffff !important;
  line-height: 0.18rem !important;
  margin-right: 0.02rem !important;
  margin-left: 0.01rem !important;
}
// active的页码样式
::v-deep .el-pager li.active {
  background: #8E7440 !important;
  box-shadow: 0 0.04rem 0.04rem 0 rgba(0,0,0,0.25) !important;
}
</style>