<template>
  <div class="comp-reg">
    <CompPopup @close="$emit('close')">
      <div class="form-box">
        <div class="form-title">密码登录</div>
        <el-input
          class="clear-input-style form-item-input"
          v-model="RegisterForm.mobile"
          placeholder="手机号码"></el-input>
        <el-input
          class="clear-input-style form-item-input"
          v-model="RegisterForm.password"
          placeholder="密码"></el-input>
        <div class="form-row3 flex-b">
          <div class="row3-left flex-s">
            <div class="rem-password-checkbox">
              <div class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
            <div class="rem-password-text">记住密码</div>
          </div>
          <div class="row3-right">忘记密码</div>
        </div>
        <div class="form-row3 flex-b">
          <div class="row3-left flex-s">
            <div class="rem-password-checkbox">
              <div class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
            <div class="rem-password-text">
              登录代表同意<span style="color: #fff">《用户使用条款》</span>
            </div>
          </div>
        </div>
        <div class="form-row4 flex-c long-btn-bg">登录</div>
        <div class="form-row5 flex-c">
          新用户需要验证码登录后可前往我的界面设置密码
        </div>
        <div class="form-row6 flex-c">验证码登录</div>
      </div>
    </CompPopup>
    <!-- <div class="reg-out">
      <div class="close-box" @click="SignInQX">
        <img
          style="width: 100%"
          src="@/assets/images/mobile/loginpopupgroup/icon17-new.png"
          alt="" />
      </div>
      <div class="login-title">
        <img style="width: 100%" src="@/assets/images/mobile/loginpopupgroup/icon15.png" alt="" />
      </div>
      <div class="reg-header">
        <div class="title">注册TMS</div>
      </div>
      <div class="DZ-box-mobile">
        <el-form
          :model="RegisterForm"
          status-icon
          ref="RegisterForm"
          label-width="0px"
          class="demo-ruleForm">
          <el-form-item prop="mobile" label="">
            <div class="input-wrap">
              <img src="@/assets/images/mobile/loginpopupgroup/icon16.png" alt="" class="phone-icon" />
              <el-input
                v-model="RegisterForm.name"
                placeholder="请输入昵称"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="mobile" label="">
            <div class="input-wrap">
              <img src="@/assets/images/mobile/loginpopupgroup/icon2.png" alt="" class="phone-icon" />
              <el-input
                v-model="RegisterForm.mobile"
                placeholder="请输入手机号"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="" class="YZM-box" prop="verify">
            <div class="input-wrap code">
              <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" />
              <el-input
                v-model="RegisterForm.verify"
                placeholder="请输入验证码"></el-input>
            </div>
            <div class="YZM" @click="GetYanZhengMa(1)">
              {{ !VerificationShow ? "发送" : "已发送" }}
              <van-count-down
                style="color: #fff; font-size: 0.1rem"
                v-show="VerificationShow"
                :time="time"
                class="CLR"
                format="ss"
                :auto-start="false"
                @finish="finish"
                ref="countDown" />
            </div>
          </el-form-item>
          <el-form-item prop="password" label="">
            <div class="input-wrap">
              <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" />
              <el-input
                v-model="RegisterForm.password"
                placeholder="请输入密码"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="password1" label="">
            <div class="input-wrap">
              <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" />
              <el-input
                v-model="RegisterForm.password1"
                placeholder="请再次输入密码"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="invite" label="">
            <div class="input-wrap">
              <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" />
              <el-input
                v-model="RegisterForm.invite"
                placeholder="请输入邀请码"></el-input>
            </div>
          </el-form-item>
          <div class="args">
            <div class="args-item">
              <img
                src="@/assets/images/mobile/loginpopupgroup/icon8-new.png"
                v-show="!argsStatus1"
                alt=""
                @click="argsStatus1 = true" />
              <img
                src="@/assets/images/mobile/loginpopupgroup/icon3-new.png"
                v-show="argsStatus1"
                alt=""
                @click="argsStatus1 = false" />
              我已阅读并同意《用户协议》和《隐私政策》
            </div>
          </div>
          <div class="btn-box">
            <div class="btn" @click="PostRegister">注册</div>
          </div>
        </el-form>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  Register,
  PostSendSms,
  SignIn,
  PersonalInfo,
  Arena_Box_List,
  Info,
} from "@/network/api.js";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      PWCode: true,
      argsStatus1: false,
      argsStatus2: false,
      VerificationShow: false,
      time: 60 * 1000,
      RegisterForm: {
        name: "",
        mobile: "",
        password: "",
        password1: "",
        verify: "",
        invite: "",
      },
      RegisterForm: {
        mobile: "",
        password: "",
        verify: "",
      },
    };
  },
  created() {
    if (this.$route.query?.IvtCode) {
      this.RegisterForm.invite = this.$route.query?.IvtCode;
    }
  },
  methods: {
    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "Leave",
      "GetRegisterShow",
      "GetSignInShow",
    ]),
    //取消登录
    SignInQX() {
      this.EmptySignInForm();
      this.closeFun();
      //   this.GetSignInShow(false);
    },
    //清空登录表单
    EmptySignInForm() {
      // this.RegisterForm.email = "";
      this.RegisterForm.pass = "";
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        localStorage.setItem("boxUserInfo", JSON.stringify(res.data.data));
        this.GetIndividualsShow(true);
      });
    },
    closeFun() {
      this.$emit("closePopup");
    },
    //注册操作
    PostRegister() {
      if (this.argsStatus1) {
        if (this.RegisterForm.password !== this.RegisterForm.password1) {
          this.$message({
            message: "两次输入密码不一致，请检查后重新输入",
            type: "error",
          });
          return false;
        }
        Register(
          this.RegisterForm.mobile,
          this.RegisterForm.password,
          this.RegisterForm.name,
          this.RegisterForm.verify,
          this.RegisterForm.invite
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "注册成功",
              type: "success",
            });
            this.GetRegisterShow(false);
            this.EmptyForm();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请先勾选用户协议",
          type: "error",
        });
      }
    },
    //注册获取验证码
    GetYanZhengMa(num) {
      if (this.RegisterForm.mobile) {
        PostSendSms(this.RegisterForm.mobile, num).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
            this.VerificationShow = true;
            this.$refs.countDown.start();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    finish() {
      //重置倒计时
      this.VerificationShow = false;
      this.$refs.countDown.reset();
    },
  },
};
</script>

<style lang="scss">
.comp-reg {
  .form-box {
    width: 2.75rem;
    .form-title {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      margin-bottom: 0.16rem;
    }
    .form-item-input {
      margin-bottom: 0.16rem;
      width: 100%;
      height: 0.38rem;
      background: #413f3e;
      color: red;
    }
    .form-row3 {
      margin-bottom: 0.16rem;
      .row3-left {
        .rem-password-checkbox {
          position: relative;
          width: 0.12rem;
          height: 0.12rem;
          background: #413f3e;
          margin-right: 0.08rem;
          .float-gou-icon {
            position: absolute;
            width: 0.16rem;
            height: 0.16rem;
            left: 0;
            bottom: 0;
            @include bgImg("check-box-active");
          }
        }
        .rem-password-text {
          color: #999;
          font-size: 0.12rem;
        }
      }
      .row3-right {
        font-size: 0.12rem;
        text-decoration-line: underline;
      }
    }
    .form-row4 {
      width: 100%;
      height: 0.42rem;
      background: red;
      font-weight: 500;
      font-size: 0.18rem;
      color: #312f2d;
      margin-bottom: 0.14rem;
      @include bgImg("long-btn-bg");
    }
    .form-row5 {
      font-size: 0.12rem;
      color: #999999;
      margin-bottom: 0.16rem;
    }
    .form-row6 {
      font-size: 0.12rem;
      color: #ffec45;
    }
  }
}
</style>
