<template>
  <div class="comp-tabbar">
    <div class="tabbar-fixed">
      <div
        @click="goToPage(item, index)"
        v-for="(item, index) in tabbarList"
        :key="index"
        class="tabbar-item">
        <div class="item-img">
          <img
            v-if="activeIndex == index"
            :src="require(`../../assets/newImages/${item.icon}.png`)"
            alt="" />
          <img
            v-else
            :src="require(`../../assets/newImages/${item.activeIcon}.png`)"
            alt="" />
        </div>
        <div
          class="item-title"
          :class="{ 'item-title-active': activeIndex == index }">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      activeIndex: 0,
      tabbarList: [
        {
          icon: "tabbar2",
          activeIcon: "tabbar1",
          title: "背包",
          href: "/Bag",
        },
        {
          icon: "tabbar3",
          activeIcon: "tabbar4",
          title: "商城",
          href: "/DustMall",
        },
        {
          icon: "tabbar5",
          activeIcon: "tabbar6",
          title: "首页",
          href: "/Home",
        },
        {
          icon: "tabbar7",
          activeIcon: "tabbar8",
          title: "充值",
          href: "/BagRecharge",
        },
        {
          icon: "tabbar9",
          activeIcon: "tabbar10",
          title: "我的",
          href: "/NewUser",
        },
      ],
    };
  },
  created() {
    const activeIndex = sessionStorage.getItem("activeIndex");
    if (activeIndex) {
      this.activeIndex = activeIndex;
    }
  },
  methods: {
    goToPage(item, index) {
      if (item.title == "我的" && !this.user.id) {
        this.$message.error("认证失败，请重新登录！");
        return false;
      }
      sessionStorage.setItem("activeIndex", index);
      this.activeIndex = index;
      if (item.href) {
        this.$router.push(item.href);
      }
    },
  },
};
</script>

<style lang="scss">
.comp-tabbar {
  width: 100%;
  height: 0.6rem;
  // background: rgba(0, 0, 0, 0);
  .tabbar-fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    // height: 0.6rem;
    z-index: 88;
    // background: url(../../assets/images/new/tabbar-bg.png) no-repeat center;
    // background-size: 100% 100%;
    background: linear-gradient(180deg, #5e3c08 0%, #4f3206 50%, #483518 100%);
    box-shadow: inset 0px 0.02rem 0.04rem 0px #6c5431;
    border-top: 0.02rem solid #b3894b;
    // background: blue;
    padding: 0.05rem 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabbar-item {
      width: 0.4rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .item-img {
        width: 0.36rem;
        height: 0.36rem;
        margin-bottom: 0.04rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-title {
        font-weight: 500;
        font-size: 0.12rem;
        color: #9d7a3a;
      }
      .item-title-active {
        font-size: 0.12rem;
        font-weight: 500;
        color: #edd76b;
      }
    }
  }
}
</style>
